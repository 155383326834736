export const country_en =[
    {value:1,"alpha2":"world","alpha3":"world",label:"Worldwide"},
    {value:4,"alpha2":"af","alpha3":"afg",label:"Afghanistan"},
{value:248,"alpha2":"ax","alpha3":"ala",label:"Åland Islands"},
{value:8,"alpha2":"al","alpha3":"alb",label:"Albania"},
{value:12,"alpha2":"dz","alpha3":"dza",label:"Algeria"},
{value:16,"alpha2":"as","alpha3":"asm",label:"American Samoa"},
{value:20,"alpha2":"ad","alpha3":"and",label:"Andorra"},
{value:24,"alpha2":"ao","alpha3":"ago",label:"Angola"},
{value:660,"alpha2":"ai","alpha3":"aia",label:"Anguilla"},
{value:10,"alpha2":"aq","alpha3":"ata",label:"Antarctica"},
{value:28,"alpha2":"ag","alpha3":"atg",label:"Antigua and Barbuda"},
{value:32,"alpha2":"ar","alpha3":"arg",label:"Argentina"},
{value:51,"alpha2":"am","alpha3":"arm",label:"Armenia"},
{value:533,"alpha2":"aw","alpha3":"abw",label:"Aruba"},
{value:36,"alpha2":"au","alpha3":"aus",label:"Australia"},
{value:40,"alpha2":"at","alpha3":"aut",label:"Austria"},
{value:31,"alpha2":"az","alpha3":"aze",label:"Azerbaijan"},
{value:44,"alpha2":"bs","alpha3":"bhs",label:"Bahamas"},
{value:48,"alpha2":"bh","alpha3":"bhr",label:"Bahrain"},
{value:50,"alpha2":"bd","alpha3":"bgd",label:"Bangladesh"},
{value:52,"alpha2":"bb","alpha3":"brb",label:"Barbados"},
{value:112,"alpha2":"by","alpha3":"blr",label:"Belarus"},
{value:56,"alpha2":"be","alpha3":"bel",label:"Belgium"},
{value:84,"alpha2":"bz","alpha3":"blz",label:"Belize"},
{value:204,"alpha2":"bj","alpha3":"ben",label:"Benin"},
{value:60,"alpha2":"bm","alpha3":"bmu",label:"Bermuda"},
{value:64,"alpha2":"bt","alpha3":"btn",label:"Bhutan"},
{value:68,"alpha2":"bo","alpha3":"bol",label:"Bolivia, Plurinational State of"},
{value:535,"alpha2":"bq","alpha3":"bes",label:"Bonaire, Sint Eustatius and Saba"},
{value:70,"alpha2":"ba","alpha3":"bih",label:"Bosnia and Herzegovina"},
{value:72,"alpha2":"bw","alpha3":"bwa",label:"Botswana"},
{value:74,"alpha2":"bv","alpha3":"bvt",label:"Bouvet Island"},
{value:76,"alpha2":"br","alpha3":"bra",label:"Brazil"},
{value:86,"alpha2":"io","alpha3":"iot",label:"British Indian Ocean Territory"},
{value:96,"alpha2":"bn","alpha3":"brn",label:"Brunei Darussalam"},
{value:100,"alpha2":"bg","alpha3":"bgr",label:"Bulgaria"},
{value:854,"alpha2":"bf","alpha3":"bfa",label:"Burkina Faso"},
{value:108,"alpha2":"bi","alpha3":"bdi",label:"Burundi"},
{value:132,"alpha2":"cv","alpha3":"cpv",label:"Cabo Verde"},
{value:116,"alpha2":"kh","alpha3":"khm",label:"Cambodia"},
{value:120,"alpha2":"cm","alpha3":"cmr",label:"Cameroon"},
{value:124,"alpha2":"ca","alpha3":"can",label:"Canada"},
{value:136,"alpha2":"ky","alpha3":"cym",label:"Cayman Islands"},
{value:140,"alpha2":"cf","alpha3":"caf",label:"Central African Republic"},
{value:148,"alpha2":"td","alpha3":"tcd",label:"Chad"},
{value:152,"alpha2":"cl","alpha3":"chl",label:"Chile"},
{value:156,"alpha2":"cn","alpha3":"chn",label:"China"},
{value:162,"alpha2":"cx","alpha3":"cxr",label:"Christmas Island"},
{value:166,"alpha2":"cc","alpha3":"cck",label:"Cocos (Keeling) Islands"},
{value:170,"alpha2":"co","alpha3":"col",label:"Colombia"},
{value:174,"alpha2":"km","alpha3":"com",label:"Comoros"},
{value:178,"alpha2":"cg","alpha3":"cog",label:"Congo"},
{value:180,"alpha2":"cd","alpha3":"cod",label:"Congo, Democratic Republic of the"},
{value:184,"alpha2":"ck","alpha3":"cok",label:"Cook Islands"},
{value:188,"alpha2":"cr","alpha3":"cri",label:"Costa Rica"},
{value:384,"alpha2":"ci","alpha3":"civ",label:"Côte d'Ivoire"},
{value:191,"alpha2":"hr","alpha3":"hrv",label:"Croatia"},
{value:192,"alpha2":"cu","alpha3":"cub",label:"Cuba"},
{value:531,"alpha2":"cw","alpha3":"cuw",label:"Curaçao"},
{value:196,"alpha2":"cy","alpha3":"cyp",label:"Cyprus"},
{value:203,"alpha2":"cz","alpha3":"cze",label:"Czechia"},
{value:208,"alpha2":"dk","alpha3":"dnk",label:"Denmark"},
{value:262,"alpha2":"dj","alpha3":"dji",label:"Djibouti"},
{value:212,"alpha2":"dm","alpha3":"dma",label:"Dominica"},
{value:214,"alpha2":"do","alpha3":"dom",label:"Dominican Republic"},
{value:218,"alpha2":"ec","alpha3":"ecu",label:"Ecuador"},
{value:818,"alpha2":"eg","alpha3":"egy",label:"Egypt"},
{value:222,"alpha2":"sv","alpha3":"slv",label:"El Salvador"},
{value:226,"alpha2":"gq","alpha3":"gnq",label:"Equatorial Guinea"},
{value:232,"alpha2":"er","alpha3":"eri",label:"Eritrea"},
{value:233,"alpha2":"ee","alpha3":"est",label:"Estonia"},
{value:748,"alpha2":"sz","alpha3":"swz",label:"Eswatini"},
{value:231,"alpha2":"et","alpha3":"eth",label:"Ethiopia"},
{value:238,"alpha2":"fk","alpha3":"flk",label:"Falkland Islands (Malvinas)"},
{value:234,"alpha2":"fo","alpha3":"fro",label:"Faroe Islands"},
{value:242,"alpha2":"fj","alpha3":"fji",label:"Fiji"},
{value:246,"alpha2":"fi","alpha3":"fin",label:"Finland"},
{value:250,"alpha2":"fr","alpha3":"fra",label:"France"},
{value:254,"alpha2":"gf","alpha3":"guf",label:"French Guiana"},
{value:258,"alpha2":"pf","alpha3":"pyf",label:"French Polynesia"},
{value:260,"alpha2":"tf","alpha3":"atf",label:"French Southern Territories"},
{value:266,"alpha2":"ga","alpha3":"gab",label:"Gabon"},
{value:270,"alpha2":"gm","alpha3":"gmb",label:"Gambia"},
{value:268,"alpha2":"ge","alpha3":"geo",label:"Georgia"},
{value:276,"alpha2":"de","alpha3":"deu",label:"Germany"},
{value:288,"alpha2":"gh","alpha3":"gha",label:"Ghana"},
{value:292,"alpha2":"gi","alpha3":"gib",label:"Gibraltar"},
{value:300,"alpha2":"gr","alpha3":"grc",label:"Greece"},
{value:304,"alpha2":"gl","alpha3":"grl",label:"Greenland"},
{value:308,"alpha2":"gd","alpha3":"grd",label:"Grenada"},
{value:312,"alpha2":"gp","alpha3":"glp",label:"Guadeloupe"},
{value:316,"alpha2":"gu","alpha3":"gum",label:"Guam"},
{value:320,"alpha2":"gt","alpha3":"gtm",label:"Guatemala"},
{value:831,"alpha2":"gg","alpha3":"ggy",label:"Guernsey"},
{value:324,"alpha2":"gn","alpha3":"gin",label:"Guinea"},
{value:624,"alpha2":"gw","alpha3":"gnb",label:"Guinea-Bissau"},
{value:328,"alpha2":"gy","alpha3":"guy",label:"Guyana"},
{value:332,"alpha2":"ht","alpha3":"hti",label:"Haiti"},
{value:334,"alpha2":"hm","alpha3":"hmd",label:"Heard Island and McDonald Islands"},
{value:336,"alpha2":"va","alpha3":"vat",label:"Holy See"},
{value:340,"alpha2":"hn","alpha3":"hnd",label:"Honduras"},
{value:344,"alpha2":"hk","alpha3":"hkg",label:"Hong Kong"},
{value:348,"alpha2":"hu","alpha3":"hun",label:"Hungary"},
{value:352,"alpha2":"is","alpha3":"isl",label:"Iceland"},
{value:356,"alpha2":"in","alpha3":"ind",label:"India"},
{value:360,"alpha2":"id","alpha3":"idn",label:"Indonesia"},
{value:364,"alpha2":"ir","alpha3":"irn",label:"Iran, Islamic Republic of"},
{value:368,"alpha2":"iq","alpha3":"irq",label:"Iraq"},
{value:372,"alpha2":"ie","alpha3":"irl",label:"Ireland"},
{value:833,"alpha2":"im","alpha3":"imn",label:"Isle of Man"},
{value:376,"alpha2":"il","alpha3":"isr",label:"Israel"},
{value:380,"alpha2":"it","alpha3":"ita",label:"Italy"},
{value:388,"alpha2":"jm","alpha3":"jam",label:"Jamaica"},
{value:392,"alpha2":"jp","alpha3":"jpn",label:"Japan"},
{value:832,"alpha2":"je","alpha3":"jey",label:"Jersey"},
{value:400,"alpha2":"jo","alpha3":"jor",label:"Jordan"},
{value:398,"alpha2":"kz","alpha3":"kaz",label:"Kazakhstan"},
{value:404,"alpha2":"ke","alpha3":"ken",label:"Kenya"},
{value:296,"alpha2":"ki","alpha3":"kir",label:"Kiribati"},
{value:408,"alpha2":"kp","alpha3":"prk",label:"Korea, Democratic People's Republic of"},
{value:410,"alpha2":"kr","alpha3":"kor",label:"Korea, Republic of"},
{value:414,"alpha2":"kw","alpha3":"kwt",label:"Kuwait"},
{value:417,"alpha2":"kg","alpha3":"kgz",label:"Kyrgyzstan"},
{value:418,"alpha2":"la","alpha3":"lao",label:"Lao People's Democratic Republic"},
{value:428,"alpha2":"lv","alpha3":"lva",label:"Latvia"},
{value:422,"alpha2":"lb","alpha3":"lbn",label:"Lebanon"},
{value:426,"alpha2":"ls","alpha3":"lso",label:"Lesotho"},
{value:430,"alpha2":"lr","alpha3":"lbr",label:"Liberia"},
{value:434,"alpha2":"ly","alpha3":"lby",label:"Libya"},
{value:438,"alpha2":"li","alpha3":"lie",label:"Liechtenstein"},
{value:440,"alpha2":"lt","alpha3":"ltu",label:"Lithuania"},
{value:442,"alpha2":"lu","alpha3":"lux",label:"Luxembourg"},
{value:446,"alpha2":"mo","alpha3":"mac",label:"Macao"},
{value:450,"alpha2":"mg","alpha3":"mdg",label:"Madagascar"},
{value:454,"alpha2":"mw","alpha3":"mwi",label:"Malawi"},
{value:458,"alpha2":"my","alpha3":"mys",label:"Malaysia"},
{value:462,"alpha2":"mv","alpha3":"mdv",label:"Maldives"},
{value:466,"alpha2":"ml","alpha3":"mli",label:"Mali"},
{value:470,"alpha2":"mt","alpha3":"mlt",label:"Malta"},
{value:584,"alpha2":"mh","alpha3":"mhl",label:"Marshall Islands"},
{value:474,"alpha2":"mq","alpha3":"mtq",label:"Martinique"},
{value:478,"alpha2":"mr","alpha3":"mrt",label:"Mauritania"},
{value:480,"alpha2":"mu","alpha3":"mus",label:"Mauritius"},
{value:175,"alpha2":"yt","alpha3":"myt",label:"Mayotte"},
{value:484,"alpha2":"mx","alpha3":"mex",label:"Mexico"},
{value:583,"alpha2":"fm","alpha3":"fsm",label:"Micronesia, Federated States of"},
{value:498,"alpha2":"md","alpha3":"mda",label:"Moldova, Republic of"},
{value:492,"alpha2":"mc","alpha3":"mco",label:"Monaco"},
{value:496,"alpha2":"mn","alpha3":"mng",label:"Mongolia"},
{value:499,"alpha2":"me","alpha3":"mne",label:"Montenegro"},
{value:500,"alpha2":"ms","alpha3":"msr",label:"Montserrat"},
{value:504,"alpha2":"ma","alpha3":"mar",label:"Morocco"},
{value:508,"alpha2":"mz","alpha3":"moz",label:"Mozambique"},
{value:104,"alpha2":"mm","alpha3":"mmr",label:"Myanmar"},
{value:516,"alpha2":"na","alpha3":"nam",label:"Namibia"},
{value:520,"alpha2":"nr","alpha3":"nru",label:"Nauru"},
{value:524,"alpha2":"np","alpha3":"npl",label:"Nepal"},
{value:528,"alpha2":"nl","alpha3":"nld",label:"Netherlands"},
{value:540,"alpha2":"nc","alpha3":"ncl",label:"New Caledonia"},
{value:554,"alpha2":"nz","alpha3":"nzl",label:"New Zealand"},
{value:558,"alpha2":"ni","alpha3":"nic",label:"Nicaragua"},
{value:562,"alpha2":"ne","alpha3":"ner",label:"Niger"},
{value:566,"alpha2":"ng","alpha3":"nga",label:"Nigeria"},
{value:570,"alpha2":"nu","alpha3":"niu",label:"Niue"},
{value:574,"alpha2":"nf","alpha3":"nfk",label:"Norfolk Island"},
{value:807,"alpha2":"mk","alpha3":"mkd",label:"North Macedonia"},
{value:580,"alpha2":"mp","alpha3":"mnp",label:"Northern Mariana Islands"},
{value:578,"alpha2":"no","alpha3":"nor",label:"Norway"},
{value:512,"alpha2":"om","alpha3":"omn",label:"Oman"},
{value:586,"alpha2":"pk","alpha3":"pak",label:"Pakistan"},
{value:585,"alpha2":"pw","alpha3":"plw",label:"Palau"},
{value:275,"alpha2":"ps","alpha3":"pse",label:"Palestine, State of"},
{value:591,"alpha2":"pa","alpha3":"pan",label:"Panama"},
{value:598,"alpha2":"pg","alpha3":"png",label:"Papua New Guinea"},
{value:600,"alpha2":"py","alpha3":"pry",label:"Paraguay"},
{value:604,"alpha2":"pe","alpha3":"per",label:"Peru"},
{value:608,"alpha2":"ph","alpha3":"phl",label:"Philippines"},
{value:612,"alpha2":"pn","alpha3":"pcn",label:"Pitcairn"},
{value:616,"alpha2":"pl","alpha3":"pol",label:"Poland"},
{value:620,"alpha2":"pt","alpha3":"prt",label:"Portugal"},
{value:630,"alpha2":"pr","alpha3":"pri",label:"Puerto Rico"},
{value:634,"alpha2":"qa","alpha3":"qat",label:"Qatar"},
{value:638,"alpha2":"re","alpha3":"reu",label:"Réunion"},
{value:642,"alpha2":"ro","alpha3":"rou",label:"Romania"},
{value:643,"alpha2":"ru","alpha3":"rus",label:"Russian Federation"},
{value:646,"alpha2":"rw","alpha3":"rwa",label:"Rwanda"},
{value:652,"alpha2":"bl","alpha3":"blm",label:"Saint Barthélemy"},
{value:654,"alpha2":"sh","alpha3":"shn",label:"Saint Helena, Ascension and Tristan da Cunha"},
{value:659,"alpha2":"kn","alpha3":"kna",label:"Saint Kitts and Nevis"},
{value:662,"alpha2":"lc","alpha3":"lca",label:"Saint Lucia"},
{value:663,"alpha2":"mf","alpha3":"maf",label:"Saint Martin (French part)"},
{value:666,"alpha2":"pm","alpha3":"spm",label:"Saint Pierre and Miquelon"},
{value:670,"alpha2":"vc","alpha3":"vct",label:"Saint Vincent and the Grenadines"},
{value:882,"alpha2":"ws","alpha3":"wsm",label:"Samoa"},
{value:674,"alpha2":"sm","alpha3":"smr",label:"San Marino"},
{value:678,"alpha2":"st","alpha3":"stp",label:"Sao Tome and Principe"},
{value:682,"alpha2":"sa","alpha3":"sau",label:"Saudi Arabia"},
{value:686,"alpha2":"sn","alpha3":"sen",label:"Senegal"},
{value:688,"alpha2":"rs","alpha3":"srb",label:"Serbia"},
{value:690,"alpha2":"sc","alpha3":"syc",label:"Seychelles"},
{value:694,"alpha2":"sl","alpha3":"sle",label:"Sierra Leone"},
{value:702,"alpha2":"sg","alpha3":"sgp",label:"Singapore"},
{value:534,"alpha2":"sx","alpha3":"sxm",label:"Sint Maarten (Dutch part)"},
{value:703,"alpha2":"sk","alpha3":"svk",label:"Slovakia"},
{value:705,"alpha2":"si","alpha3":"svn",label:"Slovenia"},
{value:90,"alpha2":"sb","alpha3":"slb",label:"Solomon Islands"},
{value:706,"alpha2":"so","alpha3":"som",label:"Somalia"},
{value:710,"alpha2":"za","alpha3":"zaf",label:"South Africa"},
{value:239,"alpha2":"gs","alpha3":"sgs",label:"South Georgia and the South Sandwich Islands"},
{value:728,"alpha2":"ss","alpha3":"ssd",label:"South Sudan"},
{value:724,"alpha2":"es","alpha3":"esp",label:"Spain"},
{value:144,"alpha2":"lk","alpha3":"lka",label:"Sri Lanka"},
{value:729,"alpha2":"sd","alpha3":"sdn",label:"Sudan"},
{value:740,"alpha2":"sr","alpha3":"sur",label:"Suriname"},
{value:744,"alpha2":"sj","alpha3":"sjm",label:"Svalbard and Jan Mayen"},
{value:752,"alpha2":"se","alpha3":"swe",label:"Sweden"},
{value:756,"alpha2":"ch","alpha3":"che",label:"Switzerland"},
{value:760,"alpha2":"sy","alpha3":"syr",label:"Syrian Arab Republic"},
{value:158,"alpha2":"tw","alpha3":"twn",label:"Taiwan, Province of China"},
{value:762,"alpha2":"tj","alpha3":"tjk",label:"Tajikistan"},
{value:834,"alpha2":"tz","alpha3":"tza",label:"Tanzania, United Republic of"},
{value:764,"alpha2":"th","alpha3":"tha",label:"Thailand"},
{value:626,"alpha2":"tl","alpha3":"tls",label:"Timor-Leste"},
{value:768,"alpha2":"tg","alpha3":"tgo",label:"Togo"},
{value:772,"alpha2":"tk","alpha3":"tkl",label:"Tokelau"},
{value:776,"alpha2":"to","alpha3":"ton",label:"Tonga"},
{value:780,"alpha2":"tt","alpha3":"tto",label:"Trinidad and Tobago"},
{value:788,"alpha2":"tn","alpha3":"tun",label:"Tunisia"},
{value:792,"alpha2":"tr","alpha3":"tur",label:"Türkiye"},
{value:795,"alpha2":"tm","alpha3":"tkm",label:"Turkmenistan"},
{value:796,"alpha2":"tc","alpha3":"tca",label:"Turks and Caicos Islands"},
{value:798,"alpha2":"tv","alpha3":"tuv",label:"Tuvalu"},
{value:800,"alpha2":"ug","alpha3":"uga",label:"Uganda"},
{value:804,"alpha2":"ua","alpha3":"ukr",label:"Ukraine"},
{value:784,"alpha2":"ae","alpha3":"are",label:"United Arab Emirates"},
{value:826,"alpha2":"gb","alpha3":"gbr",label:"United Kingdom of Great Britain and Northern Ireland"},
{value:840,"alpha2":"us","alpha3":"usa",label:"United States of America"},
{value:581,"alpha2":"um","alpha3":"umi",label:"United States Minor Outlying Islands"},
{value:858,"alpha2":"uy","alpha3":"ury",label:"Uruguay"},
{value:860,"alpha2":"uz","alpha3":"uzb",label:"Uzbekistan"},
{value:548,"alpha2":"vu","alpha3":"vut",label:"Vanuatu"},
{value:862,"alpha2":"ve","alpha3":"ven",label:"Venezuela, Bolivarian Republic of"},
{value:704,"alpha2":"vn","alpha3":"vnm",label:"Viet Nam"},
{value:92,"alpha2":"vg","alpha3":"vgb",label:"Virgin Islands (British)"},
{value:850,"alpha2":"vi","alpha3":"vir",label:"Virgin Islands (U.S.)"},
{value:876,"alpha2":"wf","alpha3":"wlf",label:"Wallis and Futuna"},
{value:732,"alpha2":"eh","alpha3":"esh",label:"Western Sahara"},
{value:887,"alpha2":"ye","alpha3":"yem",label:"Yemen"},
{value:894,"alpha2":"zm","alpha3":"zmb",label:"Zambia"},
{value:716,"alpha2":"zw","alpha3":"zwe",label:"Zimbabwe"}]