import {country_en} from "./countries/en";

export const en = {
    sidebar: {
        main: "Main Menu",
        dashboard: "Dashboard",
        ads: "Ads",
        "create ad": "Create Ad",
        "my ads": "My Ads",
        finance: "Finance",
        "my profile": "My Profile",
        "time line": "Time Line",
        "my favourite": "My Favourite",
        followers: "Followers",
        "Login Page": "Login Page",
        "signup Page": "Signup Page",
        advertisers: "Advertisers",
        affiliates: "Affiliates",
        infulencer: "Influencer",
        creatores: "Creatores",
        brands: "Brands",
        allUsers: "All Users",
        users: "Users",
        "add user": "Add User",
        "affiliate websites": "Affiliate Websites",
        "social NavLinks": "Social Links",
        "approve new ads": "Approve New Ads",
        "all ads": "All Ads",
        "approve sold ads": "Approve Sold Ads",
        "complete ads": "Complete Ads",
        "offers": "Offers",
        "all offers": "All Offers",
        "active offers": "Active Offers",
        "pending offers": "Pending Offers",
        "decline offers": "Decline Offers",
        "pending affiliate payment": "Pending Affiliate Payment",
        "pending influencer payment": "Pending Influencer Payment",
        "advertiser payment": "Advertiser Payment",
        setting: "Setting",
        "help center": "Help Center",
    },
    navbar: {
        sittings: "Sittings",
        profile: "Profile",
        logout: "Logout",
    },
    pages: {
        signup: {
            title: "Sign up",
            contactInfo: "Contact info",
            profileInfo: "Profile info",
            as: "Sign up As",
        },
        login: {
            title: "Login",
            remember: "Remember me",
            forgotPassword: "Forgot Password",
            signup: "Create a new account",
        },
        forgetPassword: {
            title: "Forgot Password",
            sendCode: "Send Code",
            message:
                "Enter your email and we will send you a link to reset your password.",
        },
        resetPassword: {
            title: "Reset Password",
            reset: "reset",
            message:
                "Enter your email and we will send you a link to reset your password.",
        },
        editProfile: {
            newEmail: "update Email",
            getCode: "Get a code",
            changePassword: "Change your password",
        },
        profile: {
            title: "My Profile",
            followers: "Followers",
            following: "Following",
            ads: "Ads",
            userData: "User Data",
            editProfile: "Edit Profile",
        },
    },
    other: {
        send: "Send",
        sendEmail: "Send Email",
        next: "Next",
        back: "Back",
        submit: "Submit",
        update: "Update",
        change: "Change",
        activate: "Activate",
        clickHere: "Click Here",
        or: "OR",
        more: "More",
    },
    error: {
        title: "Error",
        register: "register is Faild",
        inValidEmail: "Email is invalid",
        minPassword: "Password must be at least 8 characters",
        notEqualPawwords: "Password mismatch",
        code: "Please enter 6 numbers only",
        newEmail: "An error occurred while changing the email",
        updateData: "An error occurred while changing Your Data",
        notFound: "This field is empty",
    },
    data: {
        name: "Name",
        fName: "First Name",
        lName: "Last Name",
        phone: "Phone",
        email: "Email",
        ads: "Ads",
        balance: "Balance",
        action: "Actions",
        role: "Role",
        roleType: "Role Type",
        image: "Image",
        password: "Password",
        confirmPassword: "Confirm Password",
        newPassword: "New Password",
        newConfirmPassword: "New Password Confirm",
        codeVrify: "Code vrify",
        address: "Address",
        website: "Website",
        account: "Account",
        bio: "Bio",
        updateEmail: "update Email",
        oldCode: "Code in Old Email",
        newCode: "Code in New Email",
        updatePassword: "update Password",
        oldPassowrd: "Current Password",
    },
    pagination: {
        show: "MyShow",
        entries: "Entries",
        search: "Search",
        next: "Next",
        previos: "Previous",
        to: "To",
        of: "Of",
    },
    popup: {
        title: "Are you sure ?!",
        changeStatus: "Do you want to change the status of this user?",
        changeStatusDone: "Yes, change status",
        cancel: "Cancel",
    },
    role: {
        brand: "Brand",
        admin: "Admin",
        creator: "Creator",
    },
    users:{
        all : "All Users",
        brand :"All Brands",
        creator: "All Creators",
    },
    status: {
        active: "Active",
        pending: "Pending",
        cancle: "Cancel",
        accept: "Accept",
        reject: "Reject",
        delete: "Delete",
    },
    messages: {
        otp: "The activation code has been sent to your email",
        resend: "If you do not receive the code",
    },
    toasts: {
        loginAgain: "Please log in again",
        logout: "You have successfully logged out",
        newEmail: "Transfer codes have been sent to both emails",
        errorCode: "",
        updatedEmail: "Your email has been updated",
        errorUpdatedEmail: "An error occurred while changing the email",
        updatedPssword: "Your password has been updated",
        errorPassword: "An error occurred while changing the Password",
        updatedImage: "Your image has been updated",
        errorImage: "An error occurred while changing the Image",
        updatedData: "Your data has been updated",
        sendCode: "A verification code has been sent to this email",
        errorSendCode:
            "There was a problem sending the code. The email may not exist",
        login: "You have successfully logged in as ",
        errorLogin: "login failed",
        otpDone: "Verified successfully",
        otpError: "There was a problem verifying the code",
        signupSuccess: "You have successfully subscribed to the platform",
        changeStatus: "User status has been changed successfully",
    },
    countries:country_en,
    platforms:[
        {value:"instagram",label:"instagram"},
        {value:"tiktok",label:"tiktok"},
    ]
};