import {country_ar} from "./countries/ar";

export const ar = {
    sidebar: {
        main: "القائمة الرئيسية",
        dashboard: "لوحة التحكم",
        ads: "الإعلانات",
        "create ad": "إنشاء إعلان",
        "my ads": "إعلاناتي",
        finance: "المالية",
        "my profile": "ملفي الشخصي",
        "time line": "الجدول الزمني",
        "my favourite": "مفضلتي",
        followers: "المتابعين",
        "Login Page": "صفحة تسجيل الدخول",
        "signup Page": "صفحة التسجيل",
        advertisers: "المعلنين",
        affiliates: "الشركاء",
        infulencer: "المؤثر",
        creatores: "المنشئون",
        brands: "العلامات التجارية",
        allUsers: "جميع المستخدمين",
        users: "المستخدمين",
        "add user": "إضافة مستخدم",
        "affiliate websites": "مواقع الشركاء",
        "social NavLinks": "روابط التواصل الاجتماعي",
        "approve new ads": "الموافقة على الإعلانات الجديدة",
        "all ads": "جميع الإعلانات",
        "approve sold ads": "الموافقة على الإعلانات المباعة",
        "complete ads": "إعلانات مكتملة",
        "offers": "العروض",
        "all offers": "جميع العروض ",
        "active offers": "العروض النشطة",
        "pending offers": "العروض المعلقة",
        "decline offers": "العروض المرفوضة",
        "pending affiliate payment": "المدفوعات المعلقة للشركاء",
        "pending influencer payment": "المدفوعات المعلقة للمؤثرين",
        "advertiser payment": "مدفوعات المعلنين",
        setting: "الإعدادات",
        "help center": "مركز المساعدة",
    },
    navbar: {
        sittings: "الإعدادات",
        profile: "الملف الشخصي",
        logout: "تسجيل الخروج",
    },
    pages: {
        signup: {
            title: "إنشاء حساب",
            contactInfo: "معلومات الاتصال",
            profileInfo: "معلومات الملف الشخصي",
            as: "سجل كـ",
        },
        login: {
            title: "تسجيل الدخول",
            remember: "تذكرني",
            forgotPassword: "نسيت كلمة المرور",
            signup: "إنشاء حساب جديد",
        },
        forgetPassword: {
            title: "نسيت كلمة المرور",
            sendCode: "إرسال الرمز",
            message:
                "أدخل بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور.",
        },
        resetPassword: {
            title: "إعادة تعيين كلمة المرور",
            reset: "إعادة تعيين",
            message:
                "أدخل بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور.",
        },
        editProfile: {
            newEmail: "تحديث البريد الإلكتروني",
            getCode: "الحصول على رمز",
            changePassword: "تغيير كلمة المرور",
        },
        profile: {
            title: "ملفي الشخصي",
            followers: "المتابعين",
            following: "المتابَعين",
            ads: "الإعلانات",
            userData: "بيانات المستخدم",
            editProfile: "تعديل الملف الشخصي",
        },
    },
    other: {
        send: "إرسال",
        sendEmail: "إرسال بريد إلكتروني",
        next: "التالي",
        back: "العودة",
        submit: "إرسال",
        update: "تحديث",
        change: "تغيير",
        activate: "تفعيل",
        clickHere: "انقر هنا",
        or: "أو",
        more: "المزيد",
    },
    error: {
        title: "خطأ",
        register: "فشل التسجيل",
        inValidEmail: "البريد الإلكتروني غير صالح",
        minPassword: "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
        notEqualPawwords: "كلمة المرور غير متطابقة",
        code: "الرجاء إدخال 6 أرقام فقط",
        newEmail: "حدث خطأ أثناء تغيير البريد الإلكتروني",
        updateData: "حدث خطأ أثناء تحديث بياناتك",
        notFound: "هذا الحقل فارغ",
    },
    data: {
        name: "الاسم",
        fName: "الاسم الأول",
        lName: "الاسم الأخير",
        phone: "الهاتف",
        email: "البريد الإلكتروني",
        ads: "الإعلانات",
        balance: "الرصيد",
        action: "الإجراءات",
        role: "الدور",
        roleType: "نوع الدور",
        image: "الصورة",
        password: "كلمة المرور",
        confirmPassword: "تأكيد كلمة المرور",
        newPassword: "كلمة المرور الجديدة",
        newConfirmPassword: "تأكيد كلمة المرور الجديدة",
        codeVrify: "رمز التحقق",
        address: "العنوان",
        website: "الموقع الإلكتروني",
        account: "الحساب",
        bio: "السيرة الذاتية",
        updateEmail: "تحديث البريد الإلكتروني",
        oldCode: "رمز البريد الإلكتروني القديم",
        newCode: "رمز البريد الإلكتروني الجديد",
        updatePassword: "تحديث كلمة المرور",
        oldPassowrd: "كلمة المرور الحالية",
    },
    role: {
        brand: "علامة تجارية",
        admin: "مسؤول",
        creator: "منشئ",
    },
    users: {
        all: "جميع المستخدمين",
        brand: "جميع العلامات التجارية",
        creator: "جميع المنشئين",
    },
    pagination: {
        show: "عرض",
        entries: "مدخلات",
        search: "بحث",
        next: "التالي",
        previos: "السابق",
        to: "إلى",
        of: "من",
    },
    status: {
        active: "نشط",
        pending: "قيد الانتظار",
        cancle: "إلغاء",
        accept: "قبول",
        reject: "رفض",
        delete: "حذف",
    },
    messages: {
        otp: "تم إرسال رمز التفعيل إلى بريدك الإلكتروني",
        resend: "إذا لم تستلم الرمز",
    },
    popup: {
        title: "هل أنت متأكد؟!",
        changeStatus: "هل تريد تغيير حالة هذا المستخدم؟",
        changeStatusDone: "نعم، تغيير الحالة",
        cancel: "إلغاء",
    },
    toasts: {
        loginAgain: "يرجى تسجيل الدخول مرة أخرى",
        logout: "تم تسجيل الخروج بنجاح",
        newEmail: "تم إرسال رموز النقل إلى كلا البريدين الإلكترونيين",
        errorCode: "",
        updatedEmail: "تم تحديث بريدك الإلكتروني",
        errorUpdatedEmail: "حدث خطأ أثناء تغيير البريد الإلكتروني",
        updatedPssword: "تم تحديث كلمة المرور الخاصة بك",
        errorPassword: "حدث خطأ أثناء تغيير كلمة المرور",
        updatedImage: "تم تحديث صورتك",
        errorImage: "حدث خطأ أثناء تغيير الصورة",
        updatedData: "تم تحديث بياناتك",
        sendCode: "تم إرسال رمز التحقق إلى هذا البريد الإلكتروني",
        errorSendCode:
            "حدثت مشكلة في إرسال الرمز. قد يكون البريد الإلكتروني غير موجود",
        login: "لقد سجلت الدخول بنجاح كـ",
        errorLogin: "فشل تسجيل الدخول",
        otpDone: "تم التحقق بنجاح",
        otpError: "حدثت مشكلة أثناء التحقق من الرمز",
        signupSuccess: "لقد اشتركت بنجاح في المنصة",
        changeStatus: "تم تغيير حالة المستخدم بنجاح",
    },
    countries:country_ar,
    platforms:[
        {value:"instagram",label:"انستغرام"},
        {value:"tiktok",label:"تيك توك"},
    ]
};
