export const country_ar = [
    {value:1,"alpha2":"world","alpha3":"world",label:"كل انحاء العالم"},
    {value:4,"alpha2":"af","alpha3":"afg",label:"أفغانستان"},
    {value:248,"alpha2":"ax","alpha3":"ala",label:"جزر أولاند"},
    {value:8,"alpha2":"al","alpha3":"alb",label:"ألبانيا"},
    {value:12,"alpha2":"dz","alpha3":"dza",label:"الجزائر"},
    {value:16,"alpha2":"as","alpha3":"asm",label:"ساموا الأمريكية"},
    {value:20,"alpha2":"ad","alpha3":"and",label:"أندورا"},
    {value:24,"alpha2":"ao","alpha3":"ago",label:"أنغولا"},
    {value:660,"alpha2":"ai","alpha3":"aia",label:"أنغويلا"},
{value:10,"alpha2":"aq","alpha3":"ata",label:"القارة القطبية الجنوبية"},
{value:28,"alpha2":"ag","alpha3":"atg",label:"أنتيغوا وباربودا"},
{value:32,"alpha2":"ar","alpha3":"arg",label:"الأرجنتين"},
{value:51,"alpha2":"am","alpha3":"arm",label:"أرمينيا"},
{value:533,"alpha2":"aw","alpha3":"abw",label:"أروبا"},
{value:36,"alpha2":"au","alpha3":"aus",label:"أستراليا"},
{value:40,"alpha2":"at","alpha3":"aut",label:"النمسا"},
{value:31,"alpha2":"az","alpha3":"aze",label:"أذربيجان"},
{value:44,"alpha2":"bs","alpha3":"bhs",label:"باهاماس"},
{value:48,"alpha2":"bh","alpha3":"bhr",label:"البحرين"},
{value:50,"alpha2":"bd","alpha3":"bgd",label:"بنغلاديش"},
{value:52,"alpha2":"bb","alpha3":"brb",label:"باربادوس"},
{value:112,"alpha2":"by","alpha3":"blr",label:"بيلاروس"},
{value:56,"alpha2":"be","alpha3":"bel",label:"بلجيكا"},
{value:84,"alpha2":"bz","alpha3":"blz",label:"بليز"},
{value:204,"alpha2":"bj","alpha3":"ben",label:"بنين"},
{value:60,"alpha2":"bm","alpha3":"bmu",label:"برمودا"},
{value:64,"alpha2":"bt","alpha3":"btn",label:"بوتان"},
{value:68,"alpha2":"bo","alpha3":"bol",label:"بوليفيا"},
{value:535,"alpha2":"bq","alpha3":"bes",label:"الجزر الكاريبية الهولندية"},
{value:70,"alpha2":"ba","alpha3":"bih",label:"البوسنة والهرسك"},
{value:72,"alpha2":"bw","alpha3":"bwa",label:"بوتسوانا"},
{value:74,"alpha2":"bv","alpha3":"bvt",label:"جزيرة بوفيه"},
{value:76,"alpha2":"br","alpha3":"bra",label:"البرازيل"},
{value:86,"alpha2":"io","alpha3":"iot",label:"إقليم المحيط الهندي البريطاني"},
{value:96,"alpha2":"bn","alpha3":"brn",label:"بروناي"},
{value:100,"alpha2":"bg","alpha3":"bgr",label:"بلغاريا"},
{value:854,"alpha2":"bf","alpha3":"bfa",label:"بوركينا فاسو"},
{value:108,"alpha2":"bi","alpha3":"bdi",label:"بوروندي"},
{value:116,"alpha2":"kh","alpha3":"khm",label:"كمبوديا"},
{value:120,"alpha2":"cm","alpha3":"cmr",label:"الكاميرون"},
{value:124,"alpha2":"ca","alpha3":"can",label:"كندا"},
{value:132,"alpha2":"cv","alpha3":"cpv",label:"الرأس الأخضر"},
{value:136,"alpha2":"ky","alpha3":"cym",label:"جزر كايمان"},
{value:140,"alpha2":"cf","alpha3":"caf",label:"جمهورية إفريقيا الوسطى"},
{value:148,"alpha2":"td","alpha3":"tcd",label:"تشاد"},
{value:152,"alpha2":"cl","alpha3":"chl",label:"تشيلي"},
{value:156,"alpha2":"cn","alpha3":"chn",label:"الصين"},
{value:162,"alpha2":"cx","alpha3":"cxr",label:"جزيرة كريسماس"},
{value:166,"alpha2":"cc","alpha3":"cck",label:"جزر كوكوس"},
{value:170,"alpha2":"co","alpha3":"col",label:"كولومبيا"},
{value:174,"alpha2":"km","alpha3":"com",label:"جزر القمر"},
{value:178,"alpha2":"cg","alpha3":"cog",label:"جمهورية الكونغو"},
{value:180,"alpha2":"cd","alpha3":"cod",label:"جمهورية الكونغو الديمقراطية"},
{value:184,"alpha2":"ck","alpha3":"cok",label:"جزر كوك"},
{value:188,"alpha2":"cr","alpha3":"cri",label:"كوستاريكا"},
{value:384,"alpha2":"ci","alpha3":"civ",label:"ساحل العاج"},
{value:191,"alpha2":"hr","alpha3":"hrv",label:"كرواتيا"},
{value:192,"alpha2":"cu","alpha3":"cub",label:"كوبا"},
{value:531,"alpha2":"cw","alpha3":"cuw",label:"كوراساو"},
{value:196,"alpha2":"cy","alpha3":"cyp",label:"قبرص"},
{value:203,"alpha2":"cz","alpha3":"cze",label:"جمهورية التشيك"},
{value:208,"alpha2":"dk","alpha3":"dnk",label:"الدنمارك"},
{value:262,"alpha2":"dj","alpha3":"dji",label:"جيبوتي"},
{value:212,"alpha2":"dm","alpha3":"dma",label:"دومينيكا"},
{value:214,"alpha2":"do","alpha3":"dom",label:"جمهورية الدومينيكان"},
{value:218,"alpha2":"ec","alpha3":"ecu",label:"الإكوادور"},
{value:818,"alpha2":"eg","alpha3":"egy",label:"مصر"},
{value:222,"alpha2":"sv","alpha3":"slv",label:"السلفادور"},
{value:226,"alpha2":"gq","alpha3":"gnq",label:"غينيا الاستوائية"},
{value:232,"alpha2":"er","alpha3":"eri",label:"إرتريا"},
{value:233,"alpha2":"ee","alpha3":"est",label:"إستونيا"},
{value:231,"alpha2":"et","alpha3":"eth",label:"إثيوبيا"},
{value:238,"alpha2":"fk","alpha3":"flk",label:"جزر فوكلاند"},
{value:234,"alpha2":"fo","alpha3":"fro",label:"جزر فارو"},
{value:242,"alpha2":"fj","alpha3":"fji",label:"فيجي"},
{value:246,"alpha2":"fi","alpha3":"fin",label:"فنلندا"},
{value:250,"alpha2":"fr","alpha3":"fra",label:"فرنسا"},
{value:254,"alpha2":"gf","alpha3":"guf",label:"غويانا الفرنسية"},
{value:258,"alpha2":"pf","alpha3":"pyf",label:"بولينزيا الفرنسية"},
{value:260,"alpha2":"tf","alpha3":"atf",label:"أراض فرنسية جنوبية وأنتارتيكية"},
{value:266,"alpha2":"ga","alpha3":"gab",label:"الغابون"},
{value:270,"alpha2":"gm","alpha3":"gmb",label:"غامبيا"},
{value:268,"alpha2":"ge","alpha3":"geo",label:"جورجيا"},
{value:276,"alpha2":"de","alpha3":"deu",label:"ألمانيا"},
{value:288,"alpha2":"gh","alpha3":"gha",label:"غانا"},
{value:292,"alpha2":"gi","alpha3":"gib",label:"جبل طارق"},
{value:300,"alpha2":"gr","alpha3":"grc",label:"اليونان"},
{value:304,"alpha2":"gl","alpha3":"grl",label:"جرينلاند"},
{value:308,"alpha2":"gd","alpha3":"grd",label:"غرينادا"},
{value:312,"alpha2":"gp","alpha3":"glp",label:"غوادلوب"},
{value:316,"alpha2":"gu","alpha3":"gum",label:"غوام"},
{value:320,"alpha2":"gt","alpha3":"gtm",label:"غواتيمالا"},
{value:831,"alpha2":"gg","alpha3":"ggy",label:"غيرنزي"},
{value:324,"alpha2":"gn","alpha3":"gin",label:"غينيا"},
{value:624,"alpha2":"gw","alpha3":"gnb",label:"غينيا بيساو"},
{value:328,"alpha2":"gy","alpha3":"guy",label:"غيانا"},
{value:332,"alpha2":"ht","alpha3":"hti",label:"هايتي"},
{value:334,"alpha2":"hm","alpha3":"hmd",label:"جزيرة هيرد وجزر ماكدونالد"},
{value:336,"alpha2":"va","alpha3":"vat",label:"الفاتيكان"},
{value:340,"alpha2":"hn","alpha3":"hnd",label:"هندوراس"},
{value:344,"alpha2":"hk","alpha3":"hkg",label:"هونغ كونغ"},
{value:348,"alpha2":"hu","alpha3":"hun",label:"المجر"},
{value:352,"alpha2":"is","alpha3":"isl",label:"آيسلندا"},
{value:356,"alpha2":"in","alpha3":"ind",label:"الهند"},
{value:360,"alpha2":"id","alpha3":"idn",label:"إندونيسيا"},
{value:364,"alpha2":"ir","alpha3":"irn",label:"إيران"},
{value:368,"alpha2":"iq","alpha3":"irq",label:"العراق"},
{value:372,"alpha2":"ie","alpha3":"irl",label:"أيرلندا"},
{value:833,"alpha2":"im","alpha3":"imn",label:"جزيرة مان"},
{value:376,"alpha2":"il","alpha3":"isr",label:"إسرائيل"},
{value:380,"alpha2":"it","alpha3":"ita",label:"إيطاليا"},
{value:388,"alpha2":"jm","alpha3":"jam",label:"جامايكا"},
{value:392,"alpha2":"jp","alpha3":"jpn",label:"اليابان"},
{value:832,"alpha2":"je","alpha3":"jey",label:"جيرزي"},
{value:400,"alpha2":"jo","alpha3":"jor",label:"الأردن"},
{value:398,"alpha2":"kz","alpha3":"kaz",label:"كازاخستان"},
{value:404,"alpha2":"ke","alpha3":"ken",label:"كينيا"},
{value:296,"alpha2":"ki","alpha3":"kir",label:"كيريباتي"},
{value:408,"alpha2":"kp","alpha3":"prk",label:"كوريا الشمالية"},
{value:410,"alpha2":"kr","alpha3":"kor",label:"كوريا الجنوبية"},
{value:414,"alpha2":"kw","alpha3":"kwt",label:"الكويت"},
{value:417,"alpha2":"kg","alpha3":"kgz",label:"قيرغيزستان"},
{value:418,"alpha2":"la","alpha3":"lao",label:"لاوس"},
{value:428,"alpha2":"lv","alpha3":"lva",label:"لاتفيا"},
{value:422,"alpha2":"lb","alpha3":"lbn",label:"لبنان"},
{value:426,"alpha2":"ls","alpha3":"lso",label:"ليسوتو"},
{value:430,"alpha2":"lr","alpha3":"lbr",label:"ليبيريا"},
{value:434,"alpha2":"ly","alpha3":"lby",label:"ليبيا"},
{value:438,"alpha2":"li","alpha3":"lie",label:"ليختنشتاين"},
{value:440,"alpha2":"lt","alpha3":"ltu",label:"ليتوانيا"},
{value:442,"alpha2":"lu","alpha3":"lux",label:"لوكسمبورغ"},
{value:446,"alpha2":"mo","alpha3":"mac",label:"ماكاو"},
{value:807,"alpha2":"mk","alpha3":"mkd",label:"مقدونيا"},
{value:450,"alpha2":"mg","alpha3":"mdg",label:"مدغشقر"},
{value:454,"alpha2":"mw","alpha3":"mwi",label:"مالاوي"},
{value:458,"alpha2":"my","alpha3":"mys",label:"ماليزيا"},
{value:462,"alpha2":"mv","alpha3":"mdv",label:"جزر المالديف"},
{value:466,"alpha2":"ml","alpha3":"mli",label:"مالي"},
{value:470,"alpha2":"mt","alpha3":"mlt",label:"مالطا"},
{value:584,"alpha2":"mh","alpha3":"mhl",label:"جزر مارشال"},
{value:474,"alpha2":"mq","alpha3":"mtq",label:"مارتينيك"},
{value:478,"alpha2":"mr","alpha3":"mrt",label:"موريتانيا"},
{value:480,"alpha2":"mu","alpha3":"mus",label:"موريشيوس"},
{value:175,"alpha2":"yt","alpha3":"myt",label:"مايوت"},
{value:484,"alpha2":"mx","alpha3":"mex",label:"المكسيك"},
{value:583,"alpha2":"fm","alpha3":"fsm",label:"ولايات ميكرونيسيا المتحدة"},
{value:504,"alpha2":"ma","alpha3":"mar",label:"المغرب"},
{value:498,"alpha2":"md","alpha3":"mda",label:"مولدوفا"},
{value:492,"alpha2":"mc","alpha3":"mco",label:"موناكو"},
{value:496,"alpha2":"mn","alpha3":"mng",label:"منغوليا"},
{value:499,"alpha2":"me","alpha3":"mne",label:"الجبل الأسود"},
{value:500,"alpha2":"ms","alpha3":"msr",label:"مونتسرات"},
{value:508,"alpha2":"mz","alpha3":"moz",label:"موزمبيق"},
{value:104,"alpha2":"mm","alpha3":"mmr",label:"ميانمار"},
{value:516,"alpha2":"na","alpha3":"nam",label:"ناميبيا"},
{value:520,"alpha2":"nr","alpha3":"nru",label:"ناورو"},
{value:524,"alpha2":"np","alpha3":"npl",label:"نيبال"},
{value:528,"alpha2":"nl","alpha3":"nld",label:"هولندا"},
{value:540,"alpha2":"nc","alpha3":"ncl",label:"كاليدونيا الجديدة"},
{value:554,"alpha2":"nz","alpha3":"nzl",label:"نيوزيلندا"},
{value:558,"alpha2":"ni","alpha3":"nic",label:"نيكاراغوا"},
{value:562,"alpha2":"ne","alpha3":"ner",label:"النيجر"},
{value:566,"alpha2":"ng","alpha3":"nga",label:"نيجيريا"},
{value:570,"alpha2":"nu","alpha3":"niu",label:"نييوي"},
{value:574,"alpha2":"nf","alpha3":"nfk",label:"جزيرة نورفولك"},
{value:580,"alpha2":"mp","alpha3":"mnp",label:"جزر ماريانا الشمالية"},
{value:578,"alpha2":"no","alpha3":"nor",label:"النرويج"},
{value:512,"alpha2":"om","alpha3":"omn",label:"عُمان"},
{value:586,"alpha2":"pk","alpha3":"pak",label:"باكستان"},
{value:585,"alpha2":"pw","alpha3":"plw",label:"بالاو"},
{value:275,"alpha2":"ps","alpha3":"pse",label:"فلسطين"},
{value:591,"alpha2":"pa","alpha3":"pan",label:"بنما"},
{value:598,"alpha2":"pg","alpha3":"png",label:"بابوا غينيا الجديدة"},
{value:600,"alpha2":"py","alpha3":"pry",label:"باراغواي"},
{value:604,"alpha2":"pe","alpha3":"per",label:"بيرو"},
{value:608,"alpha2":"ph","alpha3":"phl",label:"الفلبين"},
{value:612,"alpha2":"pn","alpha3":"pcn",label:"جزر بيتكيرن"},
{value:616,"alpha2":"pl","alpha3":"pol",label:"بولندا"},
{value:620,"alpha2":"pt","alpha3":"prt",label:"البرتغال"},
{value:630,"alpha2":"pr","alpha3":"pri",label:"بورتوريكو"},
{value:634,"alpha2":"qa","alpha3":"qat",label:"قطر"},
{value:638,"alpha2":"re","alpha3":"reu",label:"لا ريونيون"},
{value:642,"alpha2":"ro","alpha3":"rou",label:"رومانيا"},
{value:643,"alpha2":"ru","alpha3":"rus",label:"روسيا"},
{value:646,"alpha2":"rw","alpha3":"rwa",label:"رواندا"},
{value:652,"alpha2":"bl","alpha3":"blm",label:"سان بارتيلمي"},
{value:654,"alpha2":"sh","alpha3":"shn",label:"سانت هيلانة وأسينشين وتريستان دا كونا"},
{value:659,"alpha2":"kn","alpha3":"kna",label:"سانت كيتس ونيفيس"},
{value:662,"alpha2":"lc","alpha3":"lca",label:"سانت لوسيا"},
{value:663,"alpha2":"mf","alpha3":"maf",label:"تجمع سان مارتين"},
{value:666,"alpha2":"pm","alpha3":"spm",label:"سان بيير وميكلون"},
{value:670,"alpha2":"vc","alpha3":"vct",label:"سانت فينسنت والغرينادين"},
{value:882,"alpha2":"ws","alpha3":"wsm",label:"ساموا"},
{value:674,"alpha2":"sm","alpha3":"smr",label:"سان مارينو"},
{value:678,"alpha2":"st","alpha3":"stp",label:"ساو تومي وبرينسيب"},
{value:682,"alpha2":"sa","alpha3":"sau",label:"السعودية"},
{value:686,"alpha2":"sn","alpha3":"sen",label:"السنغال"},
{value:688,"alpha2":"rs","alpha3":"srb",label:"صربيا"},
{value:690,"alpha2":"sc","alpha3":"syc",label:"سيشل"},
{value:694,"alpha2":"sl","alpha3":"sle",label:"سيراليون"},
{value:702,"alpha2":"sg","alpha3":"sgp",label:"سنغافورة"},
{value:534,"alpha2":"sx","alpha3":"sxm",label:"سينت مارتن"},
{value:703,"alpha2":"sk","alpha3":"svk",label:"سلوفاكيا"},
{value:705,"alpha2":"si","alpha3":"svn",label:"سلوفينيا"},
{value:90,"alpha2":"sb","alpha3":"slb",label:"جزر سليمان"},
{value:706,"alpha2":"so","alpha3":"som",label:"الصومال"},
{value:710,"alpha2":"za","alpha3":"zaf",label:"جنوب إفريقيا"},
{value:239,"alpha2":"gs","alpha3":"sgs",label:"جورجيا الجنوبية وجزر ساندويتش الجنوبية"},
{value:728,"alpha2":"ss","alpha3":"ssd",label:"جنوب السودان"},
{value:724,"alpha2":"es","alpha3":"esp",label:"إسبانيا"},
{value:144,"alpha2":"lk","alpha3":"lka",label:"سريلانكا"},
{value:729,"alpha2":"sd","alpha3":"sdn",label:"السودان"},
{value:740,"alpha2":"sr","alpha3":"sur",label:"سورينام"},
{value:744,"alpha2":"sj","alpha3":"sjm",label:"سفالبارد ويان ماين"},
{value:748,"alpha2":"sz","alpha3":"swz",label:"إسواتيني"},
{value:752,"alpha2":"se","alpha3":"swe",label:"السويد"},
{value:756,"alpha2":"ch","alpha3":"che",label:"سويسرا"},
{value:760,"alpha2":"sy","alpha3":"syr",label:"سوريا"},
{value:158,"alpha2":"tw","alpha3":"twn",label:"تايوان"},
{value:762,"alpha2":"tj","alpha3":"tjk",label:"طاجيكستان"},
{value:834,"alpha2":"tz","alpha3":"tza",label:"تنزانيا"},
{value:764,"alpha2":"th","alpha3":"tha",label:"تايلاند"},
{value:626,"alpha2":"tl","alpha3":"tls",label:"تيمور الشرقية"},
{value:768,"alpha2":"tg","alpha3":"tgo",label:"توغو"},
{value:772,"alpha2":"tk","alpha3":"tkl",label:"توكيلاو"},
{value:776,"alpha2":"to","alpha3":"ton",label:"تونغا"},
{value:780,"alpha2":"tt","alpha3":"tto",label:"ترينيداد وتوباغو"},
{value:788,"alpha2":"tn","alpha3":"tun",label:"تونس"},
{value:792,"alpha2":"tr","alpha3":"tur",label:"تركيا"},
{value:795,"alpha2":"tm","alpha3":"tkm",label:"تركمانستان"},
{value:796,"alpha2":"tc","alpha3":"tca",label:"جزر توركس وكايكوس"},
{value:798,"alpha2":"tv","alpha3":"tuv",label:"توفالو"},
{value:800,"alpha2":"ug","alpha3":"uga",label:"أوغندا"},
{value:804,"alpha2":"ua","alpha3":"ukr",label:"أوكرانيا"},
{value:784,"alpha2":"ae","alpha3":"are",label:"الإمارات العربية المتحدة"},
{value:826,"alpha2":"gb","alpha3":"gbr",label:"المملكة المتحدة"},
{value:840,"alpha2":"us","alpha3":"usa",label:"الولايات المتحدة"},
{value:581,"alpha2":"um","alpha3":"umi",label:"جزر الولايات المتحدة الصغيرة النائية"},
{value:858,"alpha2":"uy","alpha3":"ury",label:"الأوروغواي"},
{value:860,"alpha2":"uz","alpha3":"uzb",label:"أوزبكستان"},
{value:548,"alpha2":"vu","alpha3":"vut",label:"فانواتو"},
{value:862,"alpha2":"ve","alpha3":"ven",label:"فنزويلا"},
{value:704,"alpha2":"vn","alpha3":"vnm",label:"فيتنام"},
{value:92,"alpha2":"vg","alpha3":"vgb",label:"جزر العذراء البريطانية"},
{value:850,"alpha2":"vi","alpha3":"vir",label:"جزر العذراء الأمريكية"},
{value:876,"alpha2":"wf","alpha3":"wlf",label:"واليس وفوتونا"},
{value:732,"alpha2":"eh","alpha3":"esh",label:"الصحراء الغربية"},
{value:887,"alpha2":"ye","alpha3":"yem",label:"اليمن"},
{value:894,"alpha2":"zm","alpha3":"zmb",label:"زامبيا"},
{value:716,"alpha2":"zw","alpha3":"zwe",label:"زيمبابوي"}]