import React, {lazy} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import LangContextProvider from "./main/context/LangContext";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const LazyApp = lazy(() => import("./App"));
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <LangContextProvider>
            <Router basename="/">
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <LazyApp/>
                </DevSupport>
            </Router>
        </LangContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
